<template>
  <a-layout class="index animated fadeIn">
    <a-card>
      <div class="mb15">
        <span class="mr15">
          <a-button type="primary" v-has:systemAdminCreate="() => $router.push('/system/admin_create')">
            添加管理员
          </a-button>
        </span>
      </div>
      <a-form ref="form" :model="search" :style="{ marginBottom: '20px' }" :initialValues="search" :onFinish="pageSearchChange" layout="inline">
        <a-form-item label="关键词" name="name">
          <a-input v-model:value="search.keywords" :style="{ width: '250px' }" placeholder="请输入管理员名称"></a-input>
        </a-form-item>
        <a-form-item label="用户状态" name="level">
          <a-select v-model:value="search.status" :style="{ width: '180px' }" placeholder="全部">
            <a-select-option value=""> 全部 </a-select-option>
            <a-select-option :value="1"> 正常 </a-select-option>
            <a-select-option :value="0"> 禁用 </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" htmlType="submit">搜索</a-button>
        </a-form-item>
        <a-form-item>
          <a-button type="default" @click="pageSearchReset">重置</a-button>
        </a-form-item>
      </a-form>
      <a-table :pagination="false" :loading="listLoading" bordered rowKey="id" :columns="columns" :data-source="data" emptyText="暂无数据">
        <template v-slot:account="{ record }">
          <a-tag color="#05486A" v-if='record.is_super'>默认</a-tag>
          <span>{{record.account}}</span>
        </template>
        <template v-slot:status="{ record }">
          <span>{{ record.status == 1 ? "正常" : "禁用" }}</span>
        </template>
        <template v-slot:roles="{ record }">
          <span v-if="record.is_super==1">超级管理员</span>
          <div v-else>
          <p v-for="(item,index) in record.roles" :key="index">{{item}}</p>
          </div> 
        </template>

        <template v-slot:action="{ record }" :trigger="['hover']">
          <a-dropdown-button v-if="!record.is_super">
            <a class="ant-dropdown-link"> 操作</a>
            <template v-slot:overlay>
              <a-menu>
                <a-menu-item>
                  <a v-has:systemAdminDetail="
                      () =>
                        $router.push({
                          path: '/system/admin_create',
                          query: { id: record.id, type: 'detail' },
                        })
                    " href="javascript:;">详情</a>
                </a-menu-item>
                <a-menu-item>
                  <a v-has:systemAdminEdit="
                      () =>
                        $router.push({
                          path: '/system/admin_create',
                          query: { id: record.id, type: 'edit' },
                        })
                    " href="javascript:;">修改</a>
                </a-menu-item>
                <a-menu-item v-if="record.status == 1">
                  <a v-has:systemAdminStatus="() => systemAdminStatus(0, record)" href="javascript:;">禁用</a>
                </a-menu-item>
                <a-menu-item v-if="record.status != 1">
                  <a v-has:systemAdminStatus="() => systemAdminStatus(1, record)" href="javascript:;">启用</a>
                </a-menu-item>
                <a-menu-item>
                  <a v-has:systemAdminDel="() => systemAdminDel(record)" href="javascript:;">删除</a>
                </a-menu-item>
                <a-menu-item>
                  <a v-has:systemAdminEditPassWord="()=> {showPassWord=true;itemId=record.id}" href="javascript:;">修改密码</a>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown-button>
        </template>
      </a-table>
      <a-pagination :style="{ marginTop: '20px', textAlign: 'right' }" show-size-changer show-quick-jumper :current="search.page" :page-size="search.limit" :total="total" @change="pageCurrentChange" @showSizeChange="pageSizeChange" :show-total="total => `总数: ${total} `" />
    </a-card>
    <a-modal v-model:visible="showPassWord" title="修改密码" centered :footer='null'>
      <passWord :id='itemId' @onsubmit='showPassWord=false'></passWord>
    </a-modal>
  </a-layout>
</template>
<script>
// import { DownOutlined } from '@ant-design/icons-vue';
import { ref, onMounted, inject } from "vue";
import { message } from "ant-design-vue";
import { getAdmin, statusAdmin, delAdmin } from "@/api/system";
import { $iscode } from "@/utils/app";
import passWord from "./passWord";
// 初始化默认筛选项数值
let defSearch = {
  keywords: "",
  status: "",
  page: 1,
  limit: 10,
};
export default {
  components: { passWord },
  setup(props) {
    // // 初始化 分页信息和筛选项信息
    let total = ref(1);
    let search = ref({ ...defSearch });
    // 列表数据和列头配置
    let data = ref();
    let listLoading = ref(false);
    let showPassWord = ref(false);
    let itemId = ref("");
    let columns = [
      {
        title: "用户ID",
        dataIndex: "id",
        key: "id",
        width: 120,
      },
      {
        title: "用户账号",
        dataIndex: "account",
        key: "account",
        slots: { customRender: "account" },
      },
      {
        title: "用户名称",
        dataIndex: "username",
        key: "username",
      },
      {
        title: "所属角色",
        dataIndex: "roles",
        key: "roles",
        slots: { customRender: "roles" },
      },
      {
        title: "联系电话",
        dataIndex: "phone",
        key: "phone",
        customRender: ({ text, record }) => {
          return record.phone ? record.phone : "-";
        },
      },
      {
        title: "联系地址",
        dataIndex: "address",
        key: "address",
        customRender: ({ text, record }) => {
          return record.phone ? record.phone : "-";
        },
      },
      {
        title: "用户说明",
        dataIndex: "remark",
        key: "remark",
        customRender: ({ text, record }) => {
          return record.remark ? record.remark : "-";
        },
      },
      {
        title: "状态",
        dataIndex: "status",
        key: "status",
        width: 120,
        slots: { customRender: "status" },
      },
      {
        title: "操作",
        key: "action",
        width: 120,
        slots: { customRender: "action" },
      },
    ];
    // 页面筛选项搜索
    const pageSearchChange = () => {
      search.value = { ...search.value, page: 1 };
      initData(search.value);
    };
    // 页面筛选项重置
    const pageSearchReset = () => {
      search.value = {
        ...defSearch,
        page: search.value.page,
        limit: search.value.limit,
      };
    };
    // 分页当前页切换
    const pageCurrentChange = (page, pagelimit) => {
      search.value.page = page;
      initData(search.value);
    };
    // 分页当前页显示多少条切换
    const pagelimitChange = (current, limit) => {
      search.value.page = 1;
      search.value.limit = limit;
      initData(search.value);
    };
    const initData = async (values) => {
      listLoading.value = true;
      try {
        let res = await getAdmin(values)
          .then((res) => res.data)
          .catch((error) => error);
        listLoading.value = false;
        if ($iscode(res)) {
          data.value = res.data.data;
          total.value = res.data.total;
        } else {
          message.error(res.message);
        }
      } catch (e) {
        listLoading.value = false;
      }
    };
    const action = (e) => {
      console.log(e);
    };
    onMounted(() => {
      initData(search.value);
    });
    const $Modal = inject("$Modal");
    // 修改状态
    const systemAdminStatus = (status, data) => {
      $Modal.confirm({
        content: `确定${status ? "启用" : "禁用"}该管理员？`,
        centered: true,
        onOk: async () => {
          let result = await statusAdmin(status, data.id)
            .then((res) => res.data)
            .catch((error) => error);
          if ($iscode(result, true)) {
            initData(search.value);
          }
        },
      });
    };
    // 删除管理员
    const systemAdminDel = async (data) => {
      $Modal.confirm({
        content: `确定删除该管理员？`,
        centered: true,
        onOk: async () => {
          let result = await delAdmin(data.id)
            .then((res) => res.data)
            .catch((error) => error);
          if ($iscode(result, true)) {
            initData(search.value);
          }
        },
      });
    };
    return {
      total,
      search,
      data,
      listLoading,
      columns,
      pageSearchChange,
      pageSearchReset,
      pageCurrentChange,
      pagelimitChange,
      action,
      systemAdminStatus,
      systemAdminDel,
      showPassWord,
      itemId,
    };
  },
};
</script>
