<template>
  <div class='s_bf'>
      <a-form ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-item ref="password" label="新密码" name="password">
          <a-input-password v-model:value="form.password" placeholder="请输入新密码" />
        </a-form-item>
        <a-form-item ref="confim_password" label="再次输入密码" name="confim_password">
          <a-input-password v-model:value="form.confim_password" placeholder="请再次输入密码" />
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 8, offset: 6 }">
          <a-button type="primary" @click="onSubmit" :loading="loading">
            保存
          </a-button>
        </a-form-item>
      </a-form>
  </div>
</template>

<script>
import { ref, onMounted, reactive, toRefs, inject } from 'vue';
import  {changeAdminPassword} from '@/api/system'
import { $iscode } from "@/utils/app";
// 初始化默认筛选项数值
let defForm = {
  password: '',
  confim_password:'',
  id:''
};
export default {
  props: {
    id: {
      type:Number
    },
  },
  setup(props, { emit }) {
    //pass
    const form = ref({ ...defForm });
    const state = reactive({
      loading:false,
      labelCol: { span: 6 },
      wrapperCol: { span: 15},
      rules: {
        password: [
          {
            required: true,
            message: '请输入新密码',
          }
        ],
        confim_password: [
          {
            required: true,
            message: '请再次输入新密码',
          }
        ],
      },
    });
    const $message=inject('$message')
    const ruleForm = ref();
    // 提交
    const onSubmit = () => {
      if(form.value.password!=form.value.confim_password){
        $message.error('两次密码输入不一致')
        return
      }
      ruleForm.value
        .validate()
        .then(async() => {
          state.loading = true;
          let result = await changeAdminPassword({...form.value,id:props.id}).then(res=>res.data).catch(error=>error)
          if ($iscode(result)) {
            $message.success(result.msg);
            emit('onsubmit')
          } else {
            $message.error(result.msg);
          }
          state.loading = false;
        })
        .catch((error) => {
          $message.error('请完善表单')
        });
    };
    return {
      form,
      ...toRefs(state),
      ruleForm,
      onSubmit,
    };
  },
};
</script>
